$xxl: 1600px;
$xl_2: 1400px;
$xl: 1200px;
$lg: 991px;
$md: 767px;
$sm: 575px;

$nav_for_mbl: $lg;

:root {
  --primary-color: #c7003b;
  --secondary-color: #ff873c;

  --title-color: #030f3a;
  --text-color: #4b4f60;

  --body-bg: #fceeea;
  --body-bg-2: #ffebf1;

  --light-bg: #f5e1de;
  --light-bg-2: #f4d5d0;

  --border-color: #e3e3e3;

  --space-sm: 0.667rem;
  --space-md: 0.889rem;
  --space-lg: 1.333rem;
  --space-xl: 1.778rem;
  --space-xxl: 3.778rem;
}
