.link {
  font-weight: 300;
  font-size: 0.889rem;
  line-height: 1.5;
  text-transform: capitalize;
  color: var(--title-color);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  position: relative;
  text-decoration: none;

  @media screen and (max-width: 576px) {
    font-size: 1.111rem;
  }
  .underline {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-color: var(--title-color);
    transition: width 0.7s cubic-bezier(0.77, 0, 0.175, 1);
  }
  &:hover {
    font-weight: 600;
    letter-spacing: 0.05rem;
    .underline {
      left: unset;
      right: 0;
      width: 0;
    }
  }
}

a {
  text-decoration: none;
}
