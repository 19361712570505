.wrapper {
  &.hasSubTitle {
    h2 {
      margin-bottom: var(--space-xl);
    }
  }
  &.hasMarginBottom {
    margin-bottom: var(--space-xl);
  }
}
