.title {
  margin-bottom: var(--space-xl);
}

.details {
  display: flex;
  align-items: center;
  gap: 0 var(--space-xxl);
  position: relative;
  z-index: 1;
  .row {
    display: flex;
    align-items: center;
    gap: 0 var(--space-md);
  }
}

.thumbnail {
  margin: var(--space-xxl) 0;
  img {
    width: 100%;
    display: block;
    border-radius: 5px;
  }
}

.content {
  h2 {
    font-size: 2rem !important;
  }
  > * {
    margin-bottom: var(--space-lg);
    &:last-child {
      margin-bottom: 0;
    }
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: var(--space-xxl);
    &:first-child {
      margin-top: 0;
    }
  }
  code {
    // background-color: var(--light-bg-2);
    background-color: rgba($color: #000000, $alpha: 0.03);
    display: block;
    padding: var(--space-lg);
    border-radius: 5px;
    font-size: 0.889rem;
    line-height: 1.4;
  }
  a,
  button {
    font-size: inherit;
    font-weight: inherit;
    color: var(--primary-color) !important;
    text-decoration: underline;
  }
  strong,
  b {
    font-weight: 500 !important;
    color: var(--primary-color) !important;
  }
  table {
    border-spacing: 0;
    background-color: var(--body-bg);
    width: 100%;
    text-align: left;
    th {
      // background-color: rgba($color: #000000, $alpha: 0.03);
    }
    th,
    td {
      padding: var(--space-md);
      border-bottom: 1px solid var(--border-color);
      text-align: left;
      @media screen and (max-width: 576px) {
        padding: var(--space-sm);
      }
      p {
        @media screen and (max-width: 576px) {
          font-size: 0.8rem !important;
        }
      }
    }
  }
}
