.span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 3rem;
  line-height: 1;
  color: var(--title-color);
  opacity: 0.4;
  @media screen and (max-width: 576px) {
    font-size: 2rem;
  }
  &.active {
    opacity: 1;
  }
}
