.wrapper {
  width: 200px;
  max-width: 100%;
  margin: var(--space-xl) 0;
  @media screen and (max-width: 991px) {
    margin: var(--space-lg) 0;
  }
  .value {
    margin-bottom: 1rem;
  }
  .title {
    margin-bottom: 12px;
  }
}
