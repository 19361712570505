.wrapper {
  form {
    display: block;
    width: 100%;
    background-color: var(--light-bg);
    padding: var(--space-xxl);
    border-radius: 5px;
  }
  .formTitle {
    margin-bottom: var(--space-lg);
  }
  .title {
    margin-bottom: var(--space-xxl);
  }
}
