.wrapper {
  padding: 8.333rem 0;
  @media screen and (max-width: 991px) {
    padding: 5rem 0 !important;
  }
  &:first-child {
    padding-top: 10rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fullHeight {
  min-height: 100vh;
  display: flex;
  align-items: center;
  @media screen and (max-width: 991px) {
    min-height: unset;
  }
}

.noPaddingTop {
  padding-top: 0;
}

.noPaddingBottom {
  padding-bottom: 0;
}
