.footer {
  padding-top: 8.333rem;
  padding-bottom: 4rem;
  @media screen and (max-width: 991px) {
    padding: 4rem 0;
  }
  .wrapper {
    padding-bottom: var(--space-xxl);
    a {
      text-decoration: none;
    }
  }
}
