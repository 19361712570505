.wrapper {
  .btn {
    margin-top: var(--space-xl);
    .primary {
      margin-right: var(--space-xl);
    }
  }
  .highlights {
    @media screen and (max-width: 991px) {
      margin-top: var(--space-xl);
    }
  }
}
