.button {
  font-weight: 500;
  font-size: 0.889rem;
  line-height: 1;
  letter-spacing: 0.045em;
  // text-transform: capitalize;
  color: var(--primary-color);
  text-decoration: none;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: var(--space-md) var(--space-lg);
  display: inline-block;
  background-color: transparent;
  transition: all 0.4s ease 0s;
  &.hasIcon {
    padding-left: 3.333rem;
    background-repeat: no-repeat;
    background-position: left 1.334rem center;
  }
  &.contact {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.5064 8.49364C11.7779 8.76518 11.7779 9.20544 11.5064 9.47699L8.02972 12.9537C7.75818 13.2252 7.31792 13.2252 7.04637 12.9537C6.77483 12.6821 6.77483 12.2418 7.04637 11.9703L10.523 8.49364C10.7946 8.22209 11.2348 8.22209 11.5064 8.49364Z' fill='%23C7003B'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.1672 0.073769C19.2543 -0.288619 20.2886 0.745674 19.9262 1.83284L14.1876 19.0487C13.8299 20.1218 12.4089 20.3417 11.7436 19.4269L7.10483 13.0485C7.06204 12.9897 7.01029 12.938 6.95146 12.8952L0.57311 8.25638C-0.341677 7.59108 -0.121787 6.17009 0.951294 5.81239L18.1672 0.073769ZM18.6069 1.39307L1.39106 7.13169L7.76941 11.7705C7.9459 11.8988 8.10115 12.0541 8.22951 12.2306L12.8683 18.6089L18.6069 1.39307Z' fill='%23C7003B'/%3E%3C/svg%3E%0A");
  }
  &.mail {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.00012207 2.66665C0.00012207 1.1939 1.19402 0 2.66677 0H17.3334C18.8061 0 20 1.1939 20 2.66665V13.3333C20 14.806 18.8061 15.9999 17.3334 15.9999H2.66677C1.19402 15.9999 0.00012207 14.806 0.00012207 13.3333V2.66665ZM2.66677 1.33333C1.9304 1.33333 1.33345 1.93028 1.33345 2.66665V13.3333C1.33345 14.0696 1.9304 14.6666 2.66677 14.6666H17.3334C18.0697 14.6666 18.6667 14.0696 18.6667 13.3333V2.66665C18.6667 1.93028 18.0697 1.33333 17.3334 1.33333H2.66677Z' fill='%23C7003B'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.124229 2.27916C0.338234 1.97956 0.754598 1.91016 1.05421 2.12417L9.61251 8.23724C9.84431 8.40281 10.1557 8.40281 10.3875 8.23724L18.9458 2.12417C19.2454 1.91016 19.6618 1.97956 19.8758 2.27916C20.0898 2.57877 20.0204 2.99514 19.7208 3.20914L11.1625 9.32221C10.4671 9.81892 9.53292 9.81892 8.83753 9.32221L0.279225 3.20914C-0.0203819 2.99514 -0.0897761 2.57877 0.124229 2.27916Z' fill='%23C7003B'/%3E%3C/svg%3E%0A");
  }
  &.fullWidth {
    width: 100%;
  }
  &:active {
    transform: scale(0.96);
  }
}
