.btnText {
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  [class='outer'] {
    display: inline-block;
    [class='inner'] {
      display: inline-block;
    }
  }
  &.paddingBottom {
    [class='outer'] {
      padding-bottom: 3px;
      [class='inner'] {
        padding-bottom: 3px;
      }
    }
  }
  .animText {
    display: block;
    position: absolute;
    top: 0;
    [class='outer'] {
      [class='inner'] {
        transform: translateY(110%);
      }
    }
  }
  .text,
  .animText {
    [class='inner'] {
      transition: all 0.4s ease;
    }
  }
  .text {
    display: inline-block;
    [class='outer'] {
      [class='inner'] {
        transform: translateY(0);
      }
    }
  }
  &.animate {
    .animText {
      [class='outer'] {
        [class='inner'] {
          transform: none;
        }
      }
    }
    .text {
      [class='outer'] {
        [class='inner'] {
          transform: translateY(-110%);
        }
      }
    }
  }
}
