/* -------------------------------------------
Variables
------------------------------------------- */
@import './variables.scss';

/* -------------------------------------------
Fonts
------------------------------------------- */
@import './fonts/avenir.scss';
@import './fonts/inter.scss';

/* -------------------------------------------
Grid
------------------------------------------- */
@import './grid/css-grid.scss';

html {
  font-size: 18px;
  max-width: 100%;
  overflow-x: hidden;
  @media screen and (max-width: $xl) {
    font-size: 16px;
  }
  @media screen and (max-width: $md) {
    font-size: 14px;
  }
  scroll-behavior: smooth;
}

:-moz-selection {
  /* Code for Firefox */
  color: red;
  background: yellow;
}

::selection {
  color: var(--text-color);
  background: rgba(199, 0, 60, 0.05);
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: 'Avenir LT Std';
  background-color: var(--body-bg);
  color: var(--text-color);
  max-width: 100%;
  overflow-x: hidden;
  background-image: url("data:image/svg+xml,%3Csvg width='1345' height='1917' viewBox='0 0 1345 1917' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.5' y1='-2.18557e-08' x2='0.500084' y2='1917' stroke='%23E7E4E4'/%3E%3Cline x1='112.5' y1='-2.18557e-08' x2='112.5' y2='1917' stroke='%23E7E4E4'/%3E%3Cline x1='224.5' y1='-2.18557e-08' x2='224.5' y2='1917' stroke='%23E7E4E4'/%3E%3Cline x1='336.5' y1='-2.18557e-08' x2='336.5' y2='1917' stroke='%23E7E4E4'/%3E%3Cline x1='448.5' y1='-2.18557e-08' x2='448.5' y2='1917' stroke='%23E7E4E4'/%3E%3Cline x1='560.5' y1='-2.18557e-08' x2='560.5' y2='1917' stroke='%23E7E4E4'/%3E%3Cline x1='672.5' y1='-2.18557e-08' x2='672.5' y2='1917' stroke='%23E7E4E4'/%3E%3Cline x1='784.5' y1='-2.18557e-08' x2='784.5' y2='1917' stroke='%23E7E4E4'/%3E%3Cline x1='896.5' y1='-2.18557e-08' x2='896.5' y2='1917' stroke='%23E7E4E4'/%3E%3Cline x1='1008.5' y1='-2.18557e-08' x2='1008.5' y2='1917' stroke='%23E7E4E4'/%3E%3Cline x1='1120.5' y1='-2.18557e-08' x2='1120.5' y2='1917' stroke='%23E7E4E4'/%3E%3Cline x1='1232.5' y1='-2.18557e-08' x2='1232.5' y2='1917' stroke='%23E7E4E4'/%3E%3Cline x1='1344.5' y1='-2.18557e-08' x2='1344.5' y2='1917' stroke='%23E7E4E4'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: repeat-y;
  // font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  transition: all 0.4s ease 0s;
  @media screen and (max-width: 991px) {
    background-size: 100%;
  }
  &.bgChange {
    background-color: var(--body-bg-2);
  }
  a {
    color: inherit;
  }
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.react-bubbly-transitions__bubbly-link {
  padding: 0 !important;
}
