.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .tag {
    margin-right: 10px;
    margin-top: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
