.text {
  font-weight: 300;
  font-size: 1.222rem;
  line-height: 1.45;
  color: var(--title-color);
  @media screen and (max-width: 576px) {
    font-size: 1.111rem;
  }
  &:is(h2) {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 3.333rem;
    line-height: 1.2;
    @media screen and (max-width: 991px) {
      font-size: 2.5rem;
    }
  }
  &:is(h3) {
    font-weight: 500;
    font-size: 1.333rem;
    line-height: 1.2;
    @media screen and (max-width: 991px) {
      font-size: 1.222rem;
    }
  }
  &:is(p) {
    &.smallSize {
      font-size: 0.889rem;
    }
    &.regularSize {
      font-size: 1rem;
    }
    &.mediumSize {
      font-size: 1.222rem;
    }
    &.largeSize {
      font-size: 2.222rem;
    }
    a {
      text-decoration: none;
      font-weight: 500;
      display: inline-block;
      position: relative;
      color: var(--title-color);
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: var(--text-color);
        left: 0;
        bottom: 6px;
      }
    }
  }
}

.isWhite {
  color: var(--white-shade) !important;
}

.lightWeight {
  font-weight: 300;
}
.regularWeight {
  font-weight: 400;
}
.mediumWeight {
  font-weight: 500;
}
.semiboldWeight {
  font-weight: 600;
}
.boldWeight {
  font-weight: 700;
}

.tag {
  display: inline-block;
  font-weight: 500;
  font-size: 0.889rem;
  line-height: 1;
  color: var(--title-color);
  padding: 8px 12px;
  border-radius: 4px;
  // border: 1px solid var(--border-color);
  transition: all 0.4s ease 0s;
  background-color: var(--light-bg);
  &:hover {
    background-color: var(--light-bg-2);
    border-color: var(--secondary-color);
    // color: var(--secondary-color);
  }
}
