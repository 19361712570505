.wrapper {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--body-bg);
  padding: 8rem 0 0;
  transition: all 0.4s ease 0.4s;
  transform: translateY(-100%);
  z-index: 11;
  &.active {
    transform: translateY(0);
  }
  &.removeDelay {
    transition: all 0.4s ease 0s;
  }
  .menu {
    height: 60vh;
    .list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    a,
    button {
      margin-bottom: var(--space-xl);
      @media screen and (max-width: 576px) {
        margin-bottom: var(--space-md);
      }
    }
  }
}

.layer {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--primary-color);
  padding: 8rem 0 0;
  transition: all 0.4s ease 0s;
  transform: translateY(-100%);
  &.active {
    transform: translateY(0);
  }
  &.removeDelay {
    transition: all 0.4s ease 0.4s;
  }
}
