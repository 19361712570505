.subFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-top: 1px solid var(--border-color);
  @media screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .left,
  .right {
    padding: var(--space-lg) 0;
    @media screen and (max-width: 576px) {
      padding: var(--space-sm) 0;
    }
  }
}
