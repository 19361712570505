.image {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  padding: var(--space-md 0);
  img {
    width: 150px;
    max-width: 100%;
    transition: all 0.4s ease 0s;
    filter: grayscale(100%);
    @media screen and (max-width: 576px) {
      width: 100px;
    }
    &:hover {
      filter: none;
    }
  }
}

:global {
  .logo-slider {
    .slick-list {
      padding: 0 !important;
      width: 100%;
    }
    .slick-slide {
      padding: var(--space-md) 0;
    }
    .slick-current {
      img {
        filter: none;
      }
    }
  }
}
