.header {
  padding-top: var(--space-xl);
  padding-bottom: var(--space-sm);
  position: fixed;
  z-index: 1111;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba($color: #fceeea, $alpha: 0.5);
  @media screen and (max-width: 576px) {
    padding-top: var(--space-sm);
  }
  &.scroll {
    backdrop-filter: blur(10px);
  }
  &.hide {
    .wrapper {
      opacity: 0;
    }
  }
  .wrapper {
    transition: all 0.4s ease 0s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--space-md) 0;
    position: relative;
    z-index: 111;
  }
  .right {
    display: flex;
    align-items: center;
    gap: var(--space-sm);
    img {
      display: none;
      width: 3rem;
      @media screen and (max-width: 576px) {
        display: block;
      }
    }
    .menuIcon {
      width: 1.778rem;
      height: 1.778rem;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      > div {
        width: 60%;
        height: 2px;
        background-color: var(--title-color);
        border-radius: 1px;
        margin: 3px 0;
        transition: all 0.4s ease 0s;
        &:nth-child(2) {
          width: 100%;
        }
      }
      &:hover {
        > div {
          width: 100%;
          &:nth-child(2) {
            width: 60%;
          }
        }
      }
      &.cross {
        position: relative;
        > div {
          &:nth-child(2) {
            width: 0 !important;
          }
          &:nth-child(1),
          &:nth-child(3) {
            width: 100% !important;
            position: absolute;
            left: 0;
            top: 10;
            transform: translateY(50%) rotate(45deg);
          }
          &:nth-child(3) {
            transform: translateY(50%) rotate(-45deg);
          }
        }
      }
    }
  }
  .center {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 5rem;
      @media screen and (max-width: 576px) {
        display: none;
      }
    }
  }

  .right,
  .left {
    position: relative;
    z-index: 11;
  }

  .email {
    display: flex;
    align-items: center;
    gap: 0 var(--space-md);
    a {
      color: inherit;
      text-decoration: none;
    }
    .text {
      margin-bottom: -3px;
    }
  }
}
