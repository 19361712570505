.socialLinks {
  display: flex;
  align-items: center;
  gap: 0 var(--space-sm);
  .link {
    a {
      transition: all 0.4s ease 0s;
      display: block;
      img {
        width: 2.2rem;
        height: 2.2rem;
      }
      &:hover {
        transform: scale(1.04);
      }
    }
  }
}
