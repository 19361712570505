.wrapper {
  .header {
    display: flex;
    align-items: center;
    .detail {
      text-transform: capitalize;
      padding-left: var(--space-md);
      .name {
        text-transform: uppercase;
      }
    }
  }
  .message {
    margin-top: var(--space-xl);
  }
}
