.name {
  margin-bottom: var(--space-lg);
  *,
  h1 {
    font-size: 1.334rem;
    line-height: 1.5;
    color: #b54a07;
    font-weight: 300;
    @media screen and (max-width: 576px) {
      font-size: 1.111rem;
    }
  }
  h1 {
    text-transform: uppercase;
    display: inline-block;
    font-weight: bold;
  }
}

.footer {
  margin-top: 4.444rem;
  @media screen and (max-width: 576px) {
    margin-top: var(--space-lg);
  }
  .image {
    display: flex;
    align-items: flex-end;
    height: 100%;
    @media screen and (max-width: 576px) {
      display: none;
    }
  }
}

.left,
.right {
  p {
    margin-top: var(--space-md);
  }
}

.btn {
  margin-top: var(--space-xl);
  .primary {
    margin-right: var(--space-xl);
  }
}
