.input {
  width: 100%;
  padding: var(--space-md);
  border-radius: 5px;
  border: 0;
  color: var(--text-color);
  font-size: 0.778rem;
  font-weight: 400;
  line-height: 1.142;
  // min-height: 48px;
  background-color: var(--light-bg);
  min-height: 150px;
  max-width: 100%;
  max-height: 200px;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  &::placeholder {
    color: var(--text-color);
    opacity: 0.6;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:focus {
    // border: 1px solid var(--text-color) !important;
    outline: none !important;
  }
}
