.cursor {
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  div {
    width: 10px;
    height: 10px;
    background-color: var(--primary-color);
    border-radius: 50%;
    opacity: 0.5;
  }
}
