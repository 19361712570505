.post {
  display: flex;
  align-items: center;
  gap: 0 var(--space-lg);
  margin-top: var(--space-xxl);
  width: calc(50% - 12px);
  @media screen and (max-width: 576px) {
    width: 100%;
    flex-direction: column;
  }
  &:hover {
    .thumbnail {
      &::before {
        opacity: 0;
      }
    }
  }
}

.thumbnail {
  width: 50%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
  img {
    height: 250px;
    object-fit: cover;
    @media screen and (max-width: 576px) {
      height: unset;
    }
  }
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(117.83deg, #c7003b 13.88%, #ff873c 91.21%);
    opacity: 0.3;
    transition: all 0.4s ease 0s;
  }
}

.content {
  width: calc(50% - 24px);
  @media screen and (max-width: 576px) {
    width: 100%;
    margin-top: var(--space-lg);
  }
  * {
    text-align: left !important;
  }
  .title {
    margin-bottom: var(--space-md);
  }
  .description {
    margin-bottom: var(--space-xl);
  }
  .icon {
    display: flex;
    align-items: center;
    gap: 0 var(--space-sm);
    p {
      transform: translateY(4px);
    }
  }
}
