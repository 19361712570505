.technologies {
  .list {
    margin-bottom: var(--space-xxl);
    .title {
      margin-bottom: var(--space-md);
      color: var(--primary-color);
      text-transform: uppercase;
    }
  }
}
.info {
  .text {
    margin-bottom: var(--space-xl);
  }
}
