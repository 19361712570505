.slider {
  position: relative;
  //   button {
  //     position: absolute;
  //     bottom: 0;
  //     left: 0;
  //     top: unset;
  //     right: unset;
  //     transform: translateY(300%);
  //     width: 20px;
  //     height: 20px;
  //     background-image: url("data:image/svg+xml,%3Csvg width='22' height='17' viewBox='0 0 22 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.48617e-07 8.49979C6.65381e-08 7.56091 0.273182 6.7998 0.610169 6.7998L17.3898 6.7998C17.7268 6.7998 18 7.56091 18 8.49978C18 9.43866 17.7268 10.1998 17.3898 10.1998L0.61017 10.1998C0.273182 10.1998 2.30696e-07 9.43866 1.48617e-07 8.49979Z' fill='%23FF873C'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.3295 0.340096C14.0806 -0.223228 15.1462 -0.0710065 15.7095 0.680092L21.5744 8.5L15.7095 16.3199C15.1462 17.071 14.0806 17.2232 13.3295 16.6599C12.5784 16.0966 12.4262 15.031 12.9895 14.2799L17.3245 8.5L12.9895 2.72007C12.4262 1.96897 12.5784 0.90342 13.3295 0.340096Z' fill='%23FF873C'/%3E%3C/svg%3E%0A");
  //     background-repeat: no-repeat;
  //     background-color: transparent;
  //     border: 0;
  //     background-position: center;
  //     background-size: 100%;
  //   }
  ul {
    bottom: 0;
    transform: translateY(700%);
    display: flex !important;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      width: 8px;
      height: 8px;
      background-color: var(--secondary-color);
      border-radius: 4px;
      margin: 0 4px;
      opacity: 0.4;
      transition: all 0.4s ease 0s;
      button {
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
  }
}

:global {
  .myclient-slider {
    ul {
      li {
        &.slick-active {
          opacity: 1 !important;
          width: 16px !important;
        }
      }
    }
  }
}

.logos {
  margin-top: 5.556rem;
}

.sliderWrapper {
  @media screen and (max-width: 576px) {
    margin-top: var(--space-xl);
  }
}
