.heavyText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 8.889rem;
  line-height: 1;
  color: var(--title-color);
  position: relative;
  display: inline-block;
  &.smallSize {
    font-weight: 700;
    font-size: 3rem;
    line-height: 1.4;
    @media screen and (max-width: 991px) {
      font-size: 2.5rem;
    }
  }
  @media screen and (max-width: 991px) {
    font-size: 8.111rem;
  }
  @media screen and (max-width: 767px) {
    font-size: 7.111rem;
  }
  @media screen and (max-width: 576px) {
    font-size: 4.2rem;
  }
  .circle {
    position: absolute;
    left: 4rem;
    top: -10px;
    bottom: 0;
    margin: auto;
    height: 0;
  }
}
