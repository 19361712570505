.group {
  margin-bottom: var(--space-md);
  label {
    font-weight: 300;
    font-size: 0.889rem;
    line-height: 1.45;
    color: var(--text-color);
    margin-bottom: 4px;
    span {
      color: var(--primary-color);
    }
  }
}
