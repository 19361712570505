.text {
  margin: var(--space-sm) 0;
}

.heavyText {
  color: var(--primary-color);
  opacity: 0.2;
}

.detail {
  display: flex;
  gap: 0 var(--space-sm);
}
