.circle {
  opacity: 0.3;
  transform: scale(0.3);
  @media screen and (max-width: 576px) {
    transform: scale(0.2);
  }
  &.small {
    transform: scale(0.2);
  }
}

.color1 {
  top: calc(50% - 15rem);
  left: calc(50% - 15rem);
  animation: 10s colorOne linear infinite;
  background: linear-gradient(
    90deg,
    #c7003b 1.98%,
    #c7003b 1.99%,
    #900c3e 100%
  );
  opacity: 0.8;
  filter: blur(127px);
}

.color2 {
  top: calc(37% - 22rem);
  left: calc(35% - 22rem);
  animation: colorTwo 6s linear infinite;
  background: linear-gradient(
    90deg,
    #ff873c 1.98%,
    #ff873c 1.99%,
    #900c3e 100%
  );
  opacity: 0.8;
  filter: blur(127px);
}

.color1,
.color2 {
  width: 50rem;
  height: 50rem;
  border-radius: 50%;
  position: absolute;
  opacity: 0.6;
}

@keyframes colorOne {
  0% {
    transform: rotate(0) translate(-40px) rotate(0) scale(1);
  }
  50% {
    transform: rotate(-180deg) translate(-40px) rotate(-180deg) scaleY(1.33);
  }
  to {
    transform: rotate(-360deg) translate(-40px) rotate(-360deg) scale(1);
  }
}

@keyframes colorTwo {
  0% {
    transform: rotate(0) translate(30px) rotate(0) scale(1);
  }
  33% {
    transform: rotate(180deg) translate(30px) rotate(-180deg) scaleX(1.66);
  }
  to {
    transform: rotate(360deg) translate(30px) rotate(-360deg) scale(1);
  }
}
