.service {
  padding-right: var(--space-md);
  margin-top: 2.667rem;
}

.icon {
  margin-bottom: var(--space-md);
  img {
    width: 50px;
    height: 50px;
  }
}

.title {
  margin-bottom: var(--space-md);
}

.description {
  margin-bottom: var(--space-md);
}
